@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* source-sans-pro-regular - latin */
@font-face {
	font-family: "PoppinsRegular";
	font-style: normal;
	font-weight: 400;
	src: url("./fonts/poppins/Poppins-Regular.woff2") format("woff2"),
		url("./fonts/poppins/Poppins-Regular.woff") format("woff");
}

/* source-sans-pro-700 - latin */
@font-face {
	font-family: "PoppinsBold";
	font-style: normal;
	font-weight: 700;
	src: url("./fonts/poppins/Poppins-Bold.woff2") format("woff2"),
		url("./fonts/poppins/Poppins-Bold.woff") format("woff");
}

body {
	height: 100vh;
	font-family: "PoppinsRegular", sans-serif !important;
	overflow: hidden;
	font-size: 14px;
	background-color: #eaf1d6;
}
.startText {
	font-size: 24px;
	font-family: "PoppinsRegular" !important;
	letter-spacing: 1px;
}
.splash-screen {
	height: 100vh;
	width: 100vw;
	background-color: #eaf1d6;
	background-image: url(./assets/logov3.svg);
	background-position: center center;
	background-repeat: no-repeat;
}

.App {
	height: 100vh;
	background: radial-gradient(
		rgba(132, 189, 0, 0.825721) 0%,
		rgba(200, 72, 65, 1) 77%,
		rgba(196, 38, 32, 1) 100%
	);
}
.startbg {
	background: url(./assets/bghome.png);
	background-position: bottom center;
	height: 100%;

	background-repeat: no-repeat;
	background-size: contain;
	position: relative;

	overflow: hidden;
}

.start-logos {
	position: absolute;
	right: 22vw;
}

@media only screen and (max-width: 1200px) {
	.start-logos {
		right: 5vw;
	}
}

.endbg {
	background-image: url(./assets/fondo-end.png);
	background-position: bottom 6vh center;
	height: 100vh;

	background-repeat: no-repeat;
	background-size: contain;
	position: relative;

	overflow: hidden;
}

.logodiv {
	letter-spacing: 2px;
	text-align: right;
	color: white;
	font-size: 3.6em;
}
button {
	font-family: PoppinsBold !important;
	font-size: 24px !important;
	border-radius: 10px !important;
	border: 1px solid rgba(216, 25, 37, 1);
	opacity: 1;
	background-color: rgba(216, 25, 37, 1);
	color: white;
	padding: 10px 30px 10px 30px;
	letter-spacing: 2px;
}
button img {
	margin-bottom: 4px;
}
button:hover {
	border: 1px solid white;
	box-shadow: 6px 5px 17px rgba(0, 0, 0, 0.140625);
}
.selection button {
	width: 250px;
}
.legalGebro {
	text-align: center;
	bottom: 10px;
	color: white;
}
.form-control {
	height: 60px;
	margin-bottom: 10px;
	border-radius: 5.322699px;
	border: 1px solid rgba(255, 255, 255, 0.425262) !important;
	opacity: 1;
	background-color: rgba(255, 255, 255, 0.27188) !important;
	color: white !important;
}
::placeholder {
	color: white !important;
	opacity: 1;
}
:-ms-input-placeholder {
	color: white !important;
}
::-ms-input-placeholder {
	color: white !important;
}
.btn-registro {
	background-color: black;
	color: white;
	font-family: PoppinsBold !important;
	font-size: 14px !important;
	border-radius: 10px !important;
	border: 0;
	padding: 16px 30px 16px 30px;
	letter-spacing: 2px;
	text-decoration: none;
}
.btn-next,
.btn-iniciar {
	background-color: black;
	color: white;
	font-family: PoppinsBold !important;
	font-size: 14px !important;
	border-radius: 4px !important;
	border: 0;
	padding: 16px 30px 16px 30px;
	letter-spacing: 2px;
	text-decoration: none;
}
.btn-next {
	height: 40px;
}
.check {
	cursor: pointer;
	height: 18px;
	width: 18px;
	appearance: none;
	border: 1px solid rgba(255, 255, 255, 0.425262) !important;
	background-color: rgba(255, 255, 255, 0.27188);
	border-radius: 4px !important;
}
.check:checked {
	background-color: white;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.fotoButton {
	position: relative;
	margin: 0;
	border: 2px dashed white;
	border-radius: 50%;
	height: 185px;
	width: 185px;
	background-color: #72748d;
	opacity: 0.85;
}
.image-upload > input {
	display: none;
}
.icofoto {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.icofoto-selected {
	object-fit: cover;
	border-radius: 50%;
	height: 180px !important;
	width: 180px !important;
}
.legal {
	font-size: 13px;
	padding-right: 50px;
}
.legal div {
	margin-left: 24px;
}
.bold {
	font-family: PoppinsBold !important;
}
.regular {
	font-family: "PoppinsRegular" !important;
}
.text-orange {
	color: #f36633;
}
.text-yellow {
	color: #c8ff49;
}
.text-green {
	color: #84bd00;
}
.text-white {
	color: white;
}
.txt9 {
	font-size: 9px !important;
}
.txt10 {
	font-size: 10px !important;
}
.txt11 {
	font-size: 11px !important;
}
.txt12 {
	font-size: 12px !important;
}
.txt13 {
	font-size: 13px !important;
}
.txt22 {
	font-size: 22px !important;
}
.txt24 {
	font-size: 24px !important;
}
.txt16 {
	font-size: 16px !important;
}
.txt18 {
	font-size: 18px !important;
}
.btn-add {
	position: absolute;
	top: 14px;
	right: 10px;
}
.mw170 {
	max-width: 170px !important;
}
.bg-white {
	background-color: white;
}
.bg-green {
	background-color: rgba(132, 189, 0, 1);
	width: fit-content;
}
.bg-red {
	background-color: rgba(223, 34, 49, 1);
	width: fit-content;
}
.border-green {
	border: 2px solid #84bd00 !important;
}
.border-red {
	border: 2px solid rgba(223, 34, 49, 1) !important;
}
.container-score {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background-image: linear-gradient(
		270deg,
		rgba(238, 54, 76, 1) 0%,
		rgba(216, 25, 37, 1) 100%
	);
	height: 40px;
	padding-left: 10px;
	padding-right: 10px;
}
.container-score img {
	margin-bottom: 6px;
	margin-right: 12px;
}
.timer {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid black;
	border-radius: 8px;
	color: black;
	height: 40px;
	width: 40px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 6px;
	padding-bottom: 6px;
	letter-spacing: 2px;
	font-size: 20px;
}
.timer.extra {
	border: 2px solid #d71926;
	color: #d71926;
}
.bgdoors {
	background: url(./assets/bgdoors.png);
	background-size: contain;
	height: calc(100vh - 87px);
	background-position: bottom center;
	background-repeat: no-repeat;
}

.play-button {
	position: absolute;
	top: calc(50% + 47.5px);
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.title {
	opacity: 1;
	color: rgba(215, 25, 38, 1) !important;
	font-family: PoppinsBold !important;
	font-size: 50px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	text-align: left;
}

.paciente-historial-text {
	opacity: 1;
	color: rgba(0, 0, 0, 1);
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0px;
	text-align: left;
}

.txt18-bold {
	opacity: 1;
	color: rgba(0, 0, 0, 1);
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	text-align: left;
	font-family: PoppinsBold !important;
}

.bg-intro-prueba {
	background-color: rgb(246, 246, 246);
	background-image: url("./assets/bgprueba.png");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: 100vh;
	width: 100vw !important;
}

.bgprueba {
	background-color: rgb(246, 246, 246);
	background-image: url("./assets/bgprueba.png");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: calc(100vh - 87px) !important;
	width: 100vw !important;
	padding-top: 5vh;
}
.bg-pregunta-extra {
	background-color: rgb(234, 241, 214);
	background-image: url("./assets/bg-pregunta-extra.png");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: calc(100vh - 87px) !important;
	width: 100vw !important;
	padding-top: 5vh;
}

.bg-fin-escenario {
	background-color: rgb(234, 241, 214);
	background-image: url("./assets/fondo-escenario-pasado.jpg");
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	width: 100vw !important;
	height: 100vh !important;
}
@media only screen and (max-width: 1200px) {
	.bg-fin-escenario {
		background-position: bottom 8vh center;
	}
}
.bg-fin-escenario.escenario-1 {
	background-image: url("./assets/bg-fin-escenario1.jpg");
}
.bg-fin-escenario.escenario-2 {
	background-image: url("./assets/bg-fin-escenario2.jpg");
}
.bg-fin-escenario.escenario-3 {
	background-image: url("./assets/bg-fin-escenario3.jpg");
}
.bg-fin-escenario.escenario-4 {
	background-image: url("./assets/bg-fin-escenario4.jpg");
}
.bg-fin-escenario.escenario-5 {
	background-image: url("./assets/bg-fin-escenario5.jpg");
}
.bg-fin-escenario.escenario-6 {
	background-image: url("./assets/bg-fin-escenario6.jpg");
}
.bg-fin-escenario.escenario-7 {
	background-image: url("./assets/bg-fin-escenario7.jpg");
}

.bg-results {
	background-color: rgb(234, 241, 214);
	background-image: url("./assets/bg-results.jpg");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: calc(100vh - 95px);
	width: 100vw !important;
	padding-top: 5vh;
}

.bg-ampliar-info {
	background-color: rgb(234, 241, 214);
	background-image: url("./assets/bg-ampliar-info.png");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: calc(100vh - 95px);
	width: 100vw !important;
	padding-top: 5vh;
}

.bg-memoria {
	background-color: rgb(234, 241, 214);
	background-image: url("./assets/bg-memoria.png");
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	height: calc(100vh - 87px) !important;
	width: 100vw !important;
	padding-top: 1.5vh;
}

.etapas {
	opacity: 1;
	background-image: radial-gradient(
		rgba(132, 189, 0, 0.825721) 0%,
		rgba(200, 72, 65, 1) 67%,
		rgba(199, 69, 64, 1) 86%,
		rgba(204, 36, 29, 1) 100%
	);
	height: 154px;
	background-position: bottom;
	height: 100%;
}

.txt12 {
	font-size: 12px !important;
}
.txt26 {
	font-size: 26px !important;
}
.txt28 {
	font-size: 28px !important;
}
.txt32 {
	font-size: 32px !important;
}
.txt37 {
	font-size: 37px !important;
}
.txt50 {
	font-size: 50px !important;
}
.txt80 {
	font-size: 80px !important;
}

.underline {
	text-decoration: underline;
}

.text-red {
	color: #d71926 !important;
}
.text-purple {
	color: #a721a9 !important;
}

.cursor-pointer {
	cursor: pointer;
}

.txt14 {
	font-size: 14px !important;
}

.text-left {
	text-align: left;
}

.question-num-bg {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 62px;
	height: 62px;
	border-radius: 50%;
	border: 5px solid #d71926;
	font-size: 37px;
	margin-right: 10px;
	opacity: 1;
	background-image: linear-gradient(
		270deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(224, 223, 223, 1) 100%
	);
}

.numbered-list {
	padding-left: 20px;
	font-size: 14px;
}

.numbered-list li {
	margin-right: 20px;
	font-family: PoppinsBold !important;
}

.reference-text {
	display: block;
	text-align: left;
	font-size: 14px;
}

.tag-box {
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.136009);
	opacity: 1;
	background-image: linear-gradient(
		90deg,
		rgba(132, 189, 0, 1) 0%,
		rgba(216, 25, 37, 1) 100%
	);
	padding: 10px;
	opacity: 1;
	color: rgba(255, 255, 255, 1);
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 16px;
	padding-left: 1em;
	padding-right: 1em;
	min-width: 180px !important;
	max-width: 180px !important;
	height: 54px;
	border-radius: 8px !important;
	cursor: grab;
}

.memory-separator {
	width: 100%;
	background-color: rgba(223, 34, 49, 1);
	height: 0.5px;
	opacity: 1;
}
.memory-separator.vertical {
	width: 1px;
	height: 35px;
}

.tag-box-selected,
.tag-box-solved,
.tag-box-target {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 180px !important;
	height: 54px !important;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tag-box-solved {
	border: 1px solid rgba(223, 34, 49, 1) !important;
}

.tag-box-selected {
	border-radius: 8px !important;
	border: 1px solid rgba(132, 189, 0, 1) !important;
	opacity: 1 !important;
	background-color: rgba(200, 224, 144, 1) !important;
}

.tag-box-empty-option {
	width: 50%;
	height: 3vh;
}

.tag-box-p1 {
	border-radius: 8px !important;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.136009);
	opacity: 1;
	background-image: linear-gradient(
		90deg,
		rgba(132, 189, 0, 1) 0%,
		rgba(216, 25, 37, 1) 100%
	);
	width: 165px;
	height: 54px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(255, 255, 255, 1);
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 16px;
	cursor: grab;
}

.tag-box-target-p1 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 165px;
	height: 54px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
}

.tag-box-p1-p2 {
	border-radius: 8px !important;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.136009);
	opacity: 1;
	background-image: linear-gradient(
		90deg,
		rgba(132, 189, 0, 1) 0%,
		rgba(216, 25, 37, 1) 100%
	);
	width: 86px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(255, 255, 255, 1);
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 16px;
	cursor: grab;
}

.tag-box-target-p1-p2 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 86px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
}

.tag-box-solved-p1-p2 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 163px;
	height: 54px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(223, 34, 49, 1) !important;
}

.tag-box-p5 {
	border-radius: 8px !important;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.136009);
	opacity: 1;
	background-image: linear-gradient(
		90deg,
		rgba(132, 189, 0, 1) 0%,
		rgba(216, 25, 37, 1) 100%
	);
	width: 230px;
	height: 60px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(255, 255, 255, 1);
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 16px;
	cursor: grab;
}

.tag-box-target-p5 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	height: 60px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 230px;
	color: black;
}

.tag-box-memory {
	border-radius: 6px;
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.136009);
	opacity: 1;
	background-color: rgba(249, 211, 214, 1) !important;
	opacity: 1;
	color: black !important;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: grab;
	border: 1px solid rgba(223, 34, 49, 1) !important;
	max-width: 390px;
	height: 35px;
	line-height: 16px !important;
}

.tag-box-memory.dropped {
	border-radius: 5.4px !important;
	border: 1px solid rgba(132, 189, 0, 1) !important;
	opacity: 1;
	background-color: rgba(200, 224, 144, 1) !important;
	max-width: 100% !important;
	line-height: 10px !important;
}
.tag-box-memory.empty {
	box-shadow: none !important;
	background: transparent !important;
	border: 0px solid rgba(132, 189, 0, 1) !important;
}

.tag-box-target-p6 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 100%;
	height: 35px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	line-height: 10px;
}
.tag-box-target-p6.empty {
	width: fit-content;
	background: transparent;
	max-width: 390px;
}

.tag-box-target-p7 {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: 100%;
	height: 35px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	line-height: 14px !important;
}
.tag-box-target-p7.empty {
	width: fit-content;
	background: transparent;
	max-width: 300px;
}

.w-175 {
	width: 175px !important;
}
.w260 {
	min-width: 260px !important;
	max-width: 260px !important;
}
.w-fit-content {
	width: fit-content !important;
}
.h-fit-content {
	height: fit-content !important;
}

.bg-num-intento {
	position: relative;
	opacity: 1;
	background-color: rgba(223, 34, 49, 1);
	color: white;
	width: 62px;
	height: 72px;
	text-align: center;
	font-size: 50px;
	font-weight: bold;
	border-radius: 8px;
}

.horizontal-line {
	opacity: 0.4;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	border-top: 1px solid white;
	z-index: 0;
}

.life-indicator {
	border-radius: 4px;
	opacity: 1;
	background-image: linear-gradient(
		0deg,
		rgba(216, 25, 37, 1) 0%,
		rgba(132, 189, 0, 1) 100%
	);
	width: 8px !important;
	height: 39vh !important;
}

.indicator-image {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: white;
	margin-top: -10px;
}

.bg-wrong-points-info,
.bg-extra-points-info,
.bg-points-info {
	border-radius: 4px;
	opacity: 1;
	background-color: rgba(132, 189, 0, 1);
	text-align: center;
	padding: 4px;
	padding-left: 8px;
	padding-right: 8px;
	font-family: PoppinsBold;
	color: white;
}
.bg-extra-points-info {
	background-color: #ffcd02 !important;
	color: black;
}

.bg-wrong-points-info {
	background-color: #d71926 !important;
	color: white !important;
}

.bg-title {
	border-radius: 8px;
	border: 0.214509px solid rgba(0, 0, 0, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
	width: fit-content;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 8px;
	padding-left: 8px;
	font-size: 14px;
}

.stage-bottom-bar-collapsed,
.stage-bottom-bar {
	position: absolute;
	opacity: 1;
	background-image: linear-gradient(
		270deg,
		rgba(204, 36, 29, 1) 20%,
		rgba(199, 69, 64, 1) 30%,
		rgba(132, 189, 0, 1) 100%
	);
	padding: 10px 0;
	text-align: center;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 124px;
}

.semicircle-collapsed,
.semicircle {
	width: 60px;
	height: 30px;
	background-color: #cc241d;
	border-radius: 200px 0 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	left: calc(100vw - 60px);
	top: -54px;
	position: relative;
}

.semicircle-collapsed {
	top: -30px !important;
}

.stage-bottom-bar-collapsed {
	height: 30px !important;
	margin-top: 0px !important;
	padding-top: 0px !important;
}

.vertical-separator {
	border: 3px solid rgba(223, 34, 49, 1);
	opacity: 1;
	width: 2px;
	height: 100%;
	position: absolute;
	left: 45px;
	top: 0;
	bottom: 0;
}

.input-bottom-line {
	position: absolute;
	top: 70%;
	width: 100% !important;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
}

.input-sentence-container {
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
}

.input-container input {
	width: 100%;
	border: none;
	color: black;
	background-color: transparent;
}

.input-container input:focus {
	outline: none;
}

.dropdown-toggle::after {
	display: none !important;
}

.dropdown-toggle {
	border-radius: 8px !important;
	border: 1px dashed rgba(136, 136, 136, 1) !important;
	opacity: 1 !important;
	background-color: rgba(240, 240, 240, 1) !important;
	color: #9d9d9d !important;
	width: 448px !important;
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	height: fit-content !important;
	font-size: 14px !important;
	position: relative !important;
}

.dropdown-menu {
	font-size: 14px !important;
	width: 100% !important;
	overflow-x: hidden !important;
	position: absolute !important;
}

.mw-custom {
	max-width: 10px !important;
}

.dropdown-text {
	text-align: left;
	max-width: 100%;
	white-space: normal;
	height: 50px;
}

.dropdown-item {
	max-width: 100% !important;
	white-space: pre-line !important;
}

.column-frame {
	border-radius: 6px;
	border: 1px solid rgba(223, 34, 49, 1);
	opacity: 1;
}

.bg-danger-info {
	border-radius: 6px;
	border: 1px solid rgba(223, 34, 49, 1);
	opacity: 1;
	background-color: rgba(240, 240, 240, 1);
}

.bg-selection-card {
	border-radius: 3.6px;
	border: 0.9px solid rgba(216, 216, 216, 1);
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	width: fit-content;
	height: fit-content;
	display: flex;
	text-align: left;
	justify-content: center;
	align-items: start;
}

.card-selected {
	border-radius: 5.733px !important;
	border: 2.5px solid rgba(132, 189, 0, 1) !important;
	opacity: 1 !important;
}
.card-selected.wrong {
	border: 2.5px solid #d71926 !important;
}

.btn-saber-mas {
	border: 2px solid rgba(223, 34, 49, 1);
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 4px !important;
	padding: 16px 30px 16px 30px;
	letter-spacing: 2px;
	text-decoration: none;
	display: flex;
	align-items: center;
}

.btn-saber-mas:hover {
	border: 1.5px solid rgba(223, 34, 49, 1);
	box-shadow: 6px 5px 17px rgba(0, 0, 0, 0.140625);
}

.tiempo-reloj {
	position: absolute;
	top: 35%;
	left: 22%;
	background-image: linear-gradient(
		270deg,
		rgba(204, 36, 29, 1) 20%,
		rgba(199, 69, 64, 1) 30%,
		rgba(132, 189, 0, 1) 100%
	);
	border-radius: 8px;
	color: white;
	padding: 4px;
	z-index: 1;
	width: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.85;
	height: fit-content;
	font-size: 18px;
}

.bg-light-blue {
	border-radius: 4.5px;
	border: 0.75px solid rgba(255, 255, 255, 1);
	opacity: 1;
	background-color: rgba(219, 219, 219, 1);
	width: 8%;
	min-height: 42.5vh;
	padding-left: 8px;
	padding-right: 8px;
}
@media only screen and (max-width: 1600px) {
	.bg-light-blue {
		min-height: 47vh;
	}
}

.bg-pink {
	border-radius: 4.5px;
	border: 0.75px solid rgba(255, 255, 255, 1);
	opacity: 1;
	background-color: rgba(249, 211, 214, 1);
	width: 100%;
	height: fit-content;
	min-height: 27vh;
	padding-left: 8px;
	padding-right: 8px;
}
.h-50 {
	height: 50vh !important;
}

.bg-tag-pink {
	border-radius: 6px;
	opacity: 1;
	background-color: rgba(249, 211, 214, 1);
	width: 100%;
	height: fit-content;
	padding-left: 8px;
	padding-right: 8px;
}

.h-35 {
	height: 35px !important;
}
.w-100 {
	width: 100% !important;
}

/* Scenario Bar */
.scenarioBar {
	position: absolute;
	bottom: 0;
	height: 115px;
	background-image: linear-gradient(
		270deg,
		rgba(204, 36, 29, 1) 20%,
		rgba(199, 69, 64, 1) 30%,
		rgba(132, 189, 0, 1) 100%
	);
	width: 100%;
	z-index: 1000;
}
.z-index-9 {
	z-index: -1 !important;
}
.scenarioBar.active {
	bottom: 0px;
	transition: height 0.5s ease 0s;
}
.scenarioBar.inactive {
	height: 25px;
	transition: height 0.5s ease 0s;
}
.buttonDown {
	position: absolute;
	top: -30px;
	background-color: #cc241d;
	width: 50px;
	height: 30px;
	right: 0;
	border-top-left-radius: 30px;
	text-align: center;
	cursor: pointer;
	font-size: 13px;
}
.buttonDown img {
	margin-top: 5px;
	margin-right: -10px;
}

.bg-maria-scenario1-intro {
	background-image: url(./assets/maria.svg);
	background-repeat: no-repeat;
	background-size: 450px;
	background-position: bottom 180px right 0;
}
.bg-maria-scenario1-intro.expanded {
	background-position: bottom 90px right 0;
}
.bg-maria-scenario2-intro {
	background-image: url(./assets/maria-tosiendo.svg);
	background-repeat: no-repeat;
	background-size: 420px;
	background-position: bottom 180px right 0;
}
.bg-maria-scenario2-intro.expanded {
	background-position: bottom 90px right 0;
}

.bg-historial-item {
	border-radius: 6px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
}

.points-box-container {
	border-radius: 6px;
	background: linear-gradient(
		136.43614250067466deg,
		rgba(132, 189, 0, 0.83) 0%,
		rgba(200, 72, 65, 1) 67%,
		rgba(199, 69, 64, 1) 86%,
		rgba(204, 36, 29, 1) 100%
	);
	width: 150px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.points-box {
	position: absolute;
	width: 96%;
	height: 90%;
	border-radius: 6px;
	background: #eaf1d6;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.sentence-bg {
	width: 100%;
	border-radius: 8px;
	border: 1px dashed rgba(136, 136, 136, 1);
	opacity: 1;
}

@keyframes grow {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.grow-animation {
	animation: grow 1.5s ease-in-out infinite;
}

.bg-info-box {
	min-width: 13vw;
	border-radius: 6px 0px 6px 6px 6px;
	opacity: 1;
	background-color: rgba(225, 228, 230, 1);
}

.schema-line {
	height: 2px;
	width: 5vw;
	background: rgba(223, 34, 49, 1);
	opacity: 1;
}
.schema-line.inclined-down {
	height: 1.5px;
	width: 1.6vw;
	transform: rotate(45deg);
}
.schema-line.inclined-up {
	height: 1.5px;
	width: 1.6vw;
	transform: rotate(-45deg);
}

.final-score-img-bg {
	border-radius: 50%;
	background: linear-gradient(
		rgba(132, 189, 0, 0.825721) 0%,
		rgba(200, 72, 65, 1) 77%,
		rgba(196, 38, 32, 1) 100%
	);
}

.gradient-text {
	background: linear-gradient(
		270deg,
		rgba(132, 189, 0, 0.83) 30%,
		#c84841 60%,
		#c42620 100%
	);
	-webkit-background-clip: text;
	color: transparent;
}

.teamfoto {
	position: absolute;
	border-radius: 50%;
	height: 153px;
	width: 153px;
	left: 7.5%;
	top: 7.5%;
}
.teamfoto img {
	border-radius: 50%;
	object-fit: cover;
	height: 173px;
	width: 173px;
}

.teamfoto-small {
	position: absolute;
	border-radius: 50%;
	height: 80px;
	width: 80px;
	left: 60px;
	top: 9px;
}
.teamfoto-small img {
	border-radius: 50%;
	object-fit: cover;
	height: 82px;
	width: 82px;
}

.separator {
	width: 100%;
	background-color: rgba(8, 8, 8, 0.191024);
	height: 1px;
}

.bg-ranking-item {
	border-radius: 8px;
	box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.174388);
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
}
.current-team {
	border: 2px solid #b47c35;
}

.blink {
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
.bg-transparent {
	background: transparent !important;
}
