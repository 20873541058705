/* Modals */

.modal-btn-close {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	background-image: url(../assets/close.png);
	background-repeat: no-repeat;
}

.modal-btn-close:hover {
	box-shadow: none !important;
	background-image: url(../assets/close.png);
	background-repeat: no-repeat;
}

.modal-btn-close:focus {
}

.modal-points {
	background-color: #87ca1b;
	padding: 0.2em;
	border-radius: 6px;
	color: white !important;
	font-family: gothambold !important;
}

.modal-crack-icon {
	height: 98px !important;
	width: 60px !important;
}

.modal-right {
	color: #87ca1b !important;
}
.modal-acceptable {
	color: #eecf0d !important;
}
.modal-wrong {
	color: #e54242 !important;
}

/* PDF modal */
.modal-pdf-container {
	border: 2px solid #24284f !important;
	width: 70% !important;
	height: 90%;
	padding-top: 2em !important;
	border-radius: 0 !important;
	overflow: hidden !important;
}

.modal-pdf-html {
	padding: 0.5em 0em 2em !important;
	margin: 0 !important;
	text-align: justify !important;
	line-height: inherit !important;
	color: #505050 !important;
	font-family: "Source Sans Pro Regular" !important;
	font-size: 18px !important;
	letter-spacing: 0.8px !important;
	height: 175%;
}

/* Text modal */
.modal-text-container {
	width: 90% !important;
	height: 85%;
	overflow: auto !important;
	max-height: 700px;
	padding-top: 2em !important;
	border-radius: 0 !important;
}

.modal-text-title {
	padding: 0 2em 0.5em !important;
	margin: 0 !important;
	text-align: left !important;
	font-size: 18px !important;
	letter-spacing: 1px !important;
}

.modal-text-html {
	padding: 0 2em 2em !important;
	margin: 0 !important;
	text-align: justify !important;
	line-height: inherit !important;
	font-size: 18px !important;
	letter-spacing: 0.8px !important;
	height: 100%;
}

/* Audio modal */

.modal-audio-container {
	border: 2px solid #24284f !important;
	width: 55% !important;
	min-height: 60%;
	padding: 2em 2em 2em !important;
	border-radius: 0 !important;
}

.modal-audio-icon {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
}

.modal-audio-title {
	padding: 0.8em 1em 0.5em 4em !important;
	text-align: left !important;
	color: #24284f !important;
	font-family: gothambold !important;
	font-size: 18px !important;
	letter-spacing: 1px !important;
}

.modal-audio-html {
	padding: 0.5em 0 0.5em !important;
	margin: 0 !important;
	text-align: justify !important;
	line-height: 20px !important;
	color: #505050 !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.5px !important;
	overflow: visible !important;
}

/* Quiz Completed modal */

.modal-quizCompleted-container {
	border: 2px solid #24284f !important;
	height: 80%;
	width: 70% !important;
	align-items: center;
	justify-content: center;
	padding: 2em !important;
	border-radius: 0 !important;
}

.modal-quizCompleted-icon {
	position: absolute;
	top: 1.5em;
	left: 4em;
}

.modal-quizCompleted-title {
	color: #24284f !important;
	font-family: gothambold !important;
	font-size: 18px !important;
	line-height: 22px;
	letter-spacing: 0px !important;
	font-weight: normal !important;
	padding-left: 2em !important;
}

.modal-quizCompleted-html {
	line-height: 20px !important;
	color: #505050 !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.56px !important;
}

/* Test modal */

.modal-test-container {
	border: 2px solid #24284f !important;
	height: 75%;
	width: 65% !important;
	align-items: center;
	justify-content: center;
	padding: 1em !important;
	border-radius: 0 !important;
}
.modal-test-icon {
	padding-right: 1em;
}

.modal-test-title {
	font-family: gothambold !important;
	font-size: 18px !important;
	line-height: 20px;
	letter-spacing: 0px !important;
	font-weight: normal !important;
	padding-left: 2em !important;
}

.modal-test-title-right {
	color: #87ca1b !important;
}
.modal-test-title-acceptable {
	color: #eecf0d !important;
	text-align: left !important;
	padding-top: 0em !important;
}
.modal-test-title-acceptable span {
	padding-top: 1em;
}
.modal-test-title-wrong {
	color: #e54242 !important;
}

.modal-test-html {
	line-height: 20px !important;
	color: #24284f !important;
	text-align: left !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.56px !important;
}

.modal-test-html img {
	margin-right: 1em;
}

/* Treatment modal */

.modal-treatment-container {
	border: 2px solid #24284f !important;
	height: 75%;
	width: 65% !important;
	align-items: center;
	justify-content: center;
	padding: 1em !important;
	border-radius: 0 !important;
}
.modal-treatment-item {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-inline: 2em;
}

/* Confirm modal */

.modal-confirm-container {
	border: 2px solid #24284f !important;
	height: 85%;
	width: 55% !important;
	padding: 2em 2.5em 0 2em !important;
	border-radius: 0 !important;
}

.modal-confirm-icon {
	position: absolute;
	top: 3em;
	left: 50%;
	transform: translate(-50%);
}

.modal-confirm-title {
	color: #505050 !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.5px !important;
	font-weight: normal !important;
	padding: 0 !important;
	position: absolute !important;
	left: 0;
	right: 0;
	bottom: 6em;
}

.modal-confirm-titleB {
	color: #505050 !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.5px !important;
	font-weight: normal !important;
	padding: 0 !important;
	position: absolute !important;
	left: 0;
	right: 0;
	top: 7em;
}

/* Image modal */

.modal-image-container {
	width: fit-content !important;
	max-width: 80vw !important;
	border-radius: 0 !important;
}
.modal-image-containerB {
	border: 2px solid #24284f !important;
	padding: 0.5em !important;
	width: 70% !important;
	border-radius: 0 !important;
}

.modal-image-imagefullscreen {
	margin: 0 !important;
}
.modal-image-image {
	max-width: 75% !important;
	height: auto !important;
}

/* Answer modal */

.modal-answer-container {
	border: 2px solid #24284f !important;
	height: 80%;
	width: 62% !important;
	align-items: center;
	justify-content: center;
	padding: 1em !important;
	border-radius: 0 !important;
}
.modal-answer-containerB {
	border: 2px solid #24284f !important;
	align-items: center;
	justify-content: center;
	padding: 1em !important;
	border-radius: 0 !important;
}

.modal-answer-title {
	font-family: gothambold !important;
	line-height: 25px !important;
	text-align: left !important;
	letter-spacing: 0.69px !important;
	font-size: 22px !important;
	margin-left: 75px !important;
}

.modal-answer-html {
	line-height: 25px !important;
	color: #505050 !important;
	text-align: left !important;
	font-family: gothambook !important;
	font-size: 18px !important;
	letter-spacing: 0.69px !important;
}

.modal-answer-html img {
	width: 60px !important;
	height: 80px !important;
	margin-inline: 1em;
}

/* Btn trap modal */

.modal-btn-trap {
	font-family: gothambold;
	color: #24284f !important;
	text-align: center;
	line-height: 14px;
	font-size: 16px !important;
	position: relative;
	margin: 0 2em 0 !important;
	text-decoration: none;
	height: 45px;
}

.modal-btn-trap:after {
	content: "";
	position: absolute;
	background-color: inherit;
	width: 30px;
	height: 45px;
	left: -15px;
	bottom: 0px;
	transform: skew(-30deg);
}

.modal-btn-trap:before {
	content: "";
	position: absolute;
	background: inherit;
	width: 30px;
	height: 45px;
	right: -15px;
	bottom: 0px;
	transform: skew(-30deg);
}

/* Register modal */

.modal-register-title {
	opacity: 1;
	color: rgba(27, 27, 27, 1);
	font-family: "PoppinsRegular" !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	font-style: normal !important;
	letter-spacing: 0px !important;
	text-align: center !important;
}
.btn-modal-register {
	border-radius: 7.877863px;
	opacity: 1;
	background-color: rgba(0, 0, 0, 1);
	border-color: rgba(0, 0, 0, 1);
	width: 160px;
	height: 47px;
	font-family: PoppinsBold !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	font-style: normal !important;
	letter-spacing: 0, 88px !important;
	text-align: center !important;
	text-transform: uppercase !important;
}
.btn-modal-register.red {
	background-color: rgba(216, 25, 37, 1) !important;
	border-color: rgba(216, 25, 37, 1) !important;
}
.btn-modal-register.red:hover {
	border-color: #c8ff49 !important;
	border-width: 1px !important;
}
.modal-register-container {
	border-radius: 5.3227px !important;
	opacity: 1 !important;
	background-color: rgba(255, 255, 255, 1) !important;
	padding: 60px !important;
}

/* Unirse partida modal */

.modal-duracion-juego-container,
.modal-unirse-partida-container {
	border-radius: 5.3227px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	width: 623px;
	height: 350px;
}

.modal-duracion-juego-container {
	height: 390px !important;
}

.btn-modal-crear-partida,
.btn-modal-unirse-partida {
	border-radius: 8.973656px !important;
	opacity: 1 !important;
	background-color: rgba(216, 25, 37, 1) !important;
	color: rgba(255, 255, 255, 1);
	font-family: PoppinsBold !important;
	width: 312px;
	height: 54px;
	font-size: 16px !important;
}

.btn-duracion-juego:hover,
.btn-modal-crear-partida:hover,
.btn-modal-unirse-partida:hover {
	border-color: #c8ff49 !important;
	border-width: 1px !important;
}

.btn-modal-unirse-partida {
	margin-top: 20px;
}

.btn-duracion-juego {
	border-radius: 8.973656px;
	opacity: 1;
	background-color: rgba(216, 25, 37, 1);
	opacity: 1;
	color: rgba(255, 255, 255, 1);
	font-size: 16px !important;
	font-weight: 600;
	font-style: normal;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	width: 280px !important;
	max-width: 300px !important;
	margin-bottom: 20px;
}

.modal-introducir-codigo-partida-text {
	opacity: 1;
	color: rgba(27, 27, 27, 1);
	font-size: 16px;
	letter-spacing: 0px;
	text-align: center;
}

.btn-modal-introducir-codigo-partida {
	border-radius: 7.877863px;
	opacity: 1;
	background-color: rgba(0, 0, 0, 1);
	font-size: 14px !important;
	text-transform: uppercase;
	border-color: black !important;
	max-width: 160px;
	height: 47px;
}

.input-codigo-partida {
	border-radius: 5.3227px;
	opacity: 1;
	height: 50px !important;
	max-width: 298px !important;
	margin-left: 23% !important;
	border-color: black;
	opacity: 1;
	color: black;
	font-size: 14px;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center !important;
	line-height: 15.648736px;
}
.input-codigo-partida:focus {
	border-color: black !important;
	outline: none !important;
}

.modal-introducir-codigo-partida-container {
	border-radius: 5.3227px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	width: 623px;
	height: 401px;
	text-align: center !important;
}

.modal-crear-partida-container {
	border-radius: 5.3227px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	width: 623px;
	height: 450px;
	text-align: center !important;
}

.modal-crear-partida-text {
	opacity: 1;
	color: rgba(27, 27, 27, 1) !important;
	font-size: 14px !important;
	letter-spacing: 0px !important;
	text-align: center !important;
}

.input-codigo-partida::-webkit-input-placeholder {
	opacity: 1 !important;
	color: rgba(0, 0, 0, 0.325557) !important;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	line-height: 15.648736px;
}

.swal2-validation-message {
	background-color: transparent !important;
	font-size: 14px !important;
}

/*Referencias bibliográficas modal*/

.modal-answer-explanation-container,
.modal-instruccions-container,
.modal-references-container {
	border-radius: 5.3227px !important;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1) !important;
	padding-top: 30px !important;
	padding-bottom: 40px !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	overflow-x: hidden !important;
	min-width: 700px;
	width: 900px;
}
.modal-instruccions-container {
	padding-bottom: 90px !important;
	background-size: 85px !important;
	background-position: bottom center;
	background-repeat: no-repeat;
}
.modal-instruccions-container.escenario-1 {
	background-image: url("../assets/escenario1-instructions.png");
}
.modal-instruccions-container.escenario-2 {
	background-image: url("../assets/escenario2-instructions.png");
}
.modal-instruccions-container.escenario-3 {
	background-image: url("../assets/escenario3-instructions.png");
}
.modal-instruccions-container.escenario-4 {
	background-image: url("../assets/escenario4-instructions.png");
}
.modal-instruccions-container.escenario-5 {
	background-image: url("../assets/escenario5-instructions.png");
}
.modal-instruccions-container.escenario-6 {
	background-image: url("../assets/escenario6-instructions.png");
}
.modal-instruccions-container.escenario-7 {
	background-image: url("../assets/escenario7-instructions.png");
}

.modal-answer-explanation-container {
	min-width: 800px !important;
	max-width: 70vw !important;
	background-image: url("../assets/icono-incorrecto-light.png");
	background-repeat: no-repeat;
	background-position: center right -12%;
	background-size: 20%;
}
.modal-answer-explanation-container.correct {
	background-image: url("../assets/icono-correcto-light.png") !important;
}

.modal-instruccions-container {
	max-width: 800px !important;
}

.text-red {
	color: #d71926 !important;
}
.bold {
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	font-family: PoppinsBold !important;
}

/*Answer modal */
.btn-modal-respuesta {
	border-radius: 4px;
	opacity: 1;
	background-color: rgba(0, 0, 0, 1) !important;
	color: white;
	border: none;
}

.bg-wrong-points-modal,
.bg-points-modal {
	border-radius: 16px;
	opacity: 1;
	background-color: rgba(132, 189, 0, 1);
	width: fit-content !important;
	padding: 4px;
	text-align: center !important;
	display: flex;
	justify-content: center;
}

.bg-wrong-points-modal {
	background: #d71926 !important;
}

.modal-html-container {
	text-align: center !important;
	overflow: visible !important;
}

.bg-icono-incorrecto,
.bg-icono-correcto {
	background-image: url(../assets/icono-correcto.png);
	background-repeat: no-repeat;
	background-position: top right;
}

.bg-icono-incorrecto {
	background-image: url(../assets/icono-incorrecto.png) !important;
}

.modal-resume-container {
	border-radius: 5.3227px;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1);
	padding-top: 40px !important;
	padding-bottom: 40px !important;
	padding-left: 40px !important;
	padding-right: 40px !important;
	width: 40vw;
	height: 60vh;
	min-width: 550px !important;
}
